import React from "react"
import {Helmet} from "react-helmet"
import styled from "styled-components"
import { useSpring, animated } from 'react-spring'
import Link from "gatsby-link"

import device from "../styles/device"
import colors from "../styles/colors"
import fonts from "../styles/fonts"

import Layout from "../layout"

//data:
import langData from '../data/languages/es'

const Page = styled.div`
  font-family: ${fonts.poppins};
  color: ${colors.tertiary};
  max-width: 1024px;

  margin: auto;
  padding: 100px 8.3333333%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;

  margin: 100px auto 0;

  @media ${device.tablet}{
    padding: 140px 30px;
  }

  @media ${device.phone}{
      padding: 100px 20px;
  }
`

const Container = styled.div`
  margin: 0 -10px;
`

const Title = styled.h1`
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 54px;
  margin: 0 0 60px 0;

  padding: 0 10px;

  @media ${device.phone} {
    font-size: 36px;
    line-height: 42px;
  }
`

const Content = styled.div`
  padding: 0 10px;
  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;

  }
`

const Button = styled(Link)`
  display: inline-flex;

  background-color: ${colors.primary};
  border: 1px solid ${colors.primary};
  padding: 10px 60px;
  margin: 60px auto 0 0;
  font-family: ${fonts.poppins};
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  transition: all .4s;
  text-decoration: none;

  &:hover {
    background-color: ${colors.primaryDark};
    cursor: pointer;
  }
`

const PageNotFound = () => {

  const {sideMenu, cookies, footer, errorPage} = langData;

  const titleProps = useSpring({
    from: {
      opacity: 0,
      transform: `translateY(-200px)`
    },
    to: {
      opacity: 1,
      transform: `translateY(0px)`
    }
  })

  const contentProps = useSpring({
    from: {
      opacity: 0, 
      transform: `translateY(200px)`
    },
    to: {
      opacity: 1,
      transform: `translateY(0px)`
    }
  })

  return (

    <Layout sideMenu={sideMenu} cookies={cookies} footer={footer}>

      <Helmet>
          <title>{errorPage.metaTitle}</title>
          <meta name="description" content={errorPage.metaDesc} />
      </Helmet>

      <Page>
        <Container>
            <animated.div style={titleProps}>
              <Title>
                {errorPage.title} <span role="img" aria-label="ghost">👻</span>
              </Title>
            </animated.div>
            <animated.div style={contentProps}>
              <Content>

                <p>{errorPage.text}</p>

                <Button to={errorPage.link}>
                  {errorPage.button}
                </Button>

              </Content>
            </animated.div>
        </Container>
      </Page>

    </Layout>
  )
}

export default PageNotFound